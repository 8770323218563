.search-date {
    display: flex;
    // align-items: left;

    > button {
        width: 100%;
        margin-bottom: 6px;
    }

    .button-search{
        left:-6px;
    }

    &__tag{
        // display: fixed;
        // .title{
        // }
    }
    
}