@import "./scss/index.scss";

.App {
    //overflow: visible;  /* code added to prevent scroll */
    h1 {
        color: $primary-color;
    }
    h2 {
        color: green;
    }
    span {
        font-weight: 100;
    }
}

.success {
    border: 1px solid $success;
    background-color: $background-success;
}

.error {
    border: 1px solid $error;
    background-color: $background-error;
}

#scroll-y {
    overflow-y: auto;
    overflow-x: hidden;
}

#scroll-hidden {
    overflow-y: hidden;
    overflow-x: hidden;
}