.list-users {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-switch {
            display: flex;
            align-items: center;

            > button {
                margin-right: 20px;
            }
        }
    }

    .users-active {
        background-color: #fff;
        padding: 10px 20px;

        .ant-list-item-meta {
            display: flex;
        }
    }
    .boton-export {
        align-items: right;
    }
}

.boton-user {
    margin-top: 5px;
    align-items: right;
}
.button-edit{
    margin: 6px;
    background-color: orange;
    color: white;
}
.button-activate{
    margin: 6px;
    background-color: green;
    color: white;
}