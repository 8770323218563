.add-edit-branch-form{

  &__header {
    // margin: auto;
    // width: 50%;
    // padding: 10px;
  }

  &__form{
    margin-top: 100px;
    margin-bottom: 100px;
    margin-right: 150px;
    margin-left: 80px;
  }


  .form-addedit {
    text-align: left;

    .ant-form-item {
      margin-top: 5px;
      margin-bottom: 20px;

      i {
        color: rgba(0, 0, 0, 0.25);
      }

      .button-add{
        margin-top: 20px;
      }
    }

    .ant-checkbox-group-item {
      display: block;
      margin-right: 0;
      padding-top: 100;
  }

    .btn-submit {
      margin-top: 15px;
      width: 100%;
    }
    label {
      text-align: left;
    }
    .select-form {
      text-align: left;
    }
    .control-required {
      color: red;
      text-align: left;
    }
  }
}
