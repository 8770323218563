.edit-supplier-form {
    .upload-avatar {
        display: table;
        margin: 0 auto;
        border: 2px solid #9a9a9a;
        border-style: dashed;
        border-radius: 100px;
        padding: 6px;
        margin-bottom: 10px;
    }

    .form-edit {
        text-align: left;

        .ant-form-item {
            margin-top: 5px;
            margin-bottom: 5px;

            i {
                color: rgba(0,0,0,0.25);
            }
        }

        .btn-submit {
            margin-top: 15px;
            width: 100%;
        }
        .select-form {
            width: 100%;
            text-align: left;
        }
        label {
            text-align: left;
        }
        .control-label {
            color: blue;
            text-align: left;
        }
        .control-required {
            color: red;
            text-align: left;
        }
    }
}
