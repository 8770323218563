@import "../../../scss/index.scss";

.sign-in {
  background-image: url("../../../assets/img/png/azul3.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    &-logo {
      padding: 10px 210px;
      img {
        width: 100%;
      }
    }

    &-tabs {
      background-color: $background-light;

      .ant-tabs-nav {
        width: 100% !important;
      }

      .ant-tabs-tab {
        display: block; // centers text inside tabs
        flex: 1;
        text-align: center;
        font-size: 0em + 19px / $defaultFontSize;
        border-top: 4px solid $border-grey !important;
        border-radius: 0 !important;
        margin: 0 !important;
        @media (min-width: $media-breakpoint-up-lg) {
          width: 220px;
          font-size: 0em + 23px / $defaultFontSize;
        }

        &:hover {
          color: $font-grey-dark;
        }
        &:before {
          content: none;
        }
        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ant-tabs-nav > div:nth-of-type(1) {
        display: unset !important;
        width: 100% !important;
      }

      // .ant-tabs {
      //     width: 90vw;

      //     @media (min-width: $media-breakpoint-up-lg) {
      //         width: auto;
      //     }
      // }

      .ant-tabs-nav-container {
        height: 60px !important;
      }
     
      .ant-tabs-tab-active {
        color: $primary-color !important;
        border-top: 4px solid $primary-color !important;
        border-radius: 0 !important;
        border-right: 0 !important;
        border-left: 0 !important;
      }

      .ant-tabs-content {
        width: 100%;
        padding: 0 16px 16px 16px;
        text-align: center;
      }
    }
  }
}
