.list-inventories {

   &__header {
       display: flex;
       align-items: center;
       justify-content: space-between;
       margin-bottom: 20px;

       &-switch {
        display: flex;
        align-items: left;

        > button {
            margin-right: 20px;
        }
    }

   }
   &__search{
       margin-bottom: 20px;
   }
   .button-inventory{
       margin: 6px;
   }

}
