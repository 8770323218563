@charset "UTF-8";
.list-items {
  /* src/components/CoverImage.css */ }
  .list-items__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .list-items__header-switch {
      display: flex;
      align-items: center; }
      .list-items__header-switch > button {
        margin-right: 20px; }
  .list-items .my-row {
    margin-top: 15px;
    /* Ajusta el valor según sea necesario */ }
  .list-items__search {
    display: block;
    margin-bottom: 30px; }
  .list-items__categories {
    margin-top: 30px; }
  .list-items label {
    color: black; }
  .list-items .button-specialty {
    margin: 6px; }
  .list-items .button-edit {
    margin: 6px;
    background-color: orange;
    color: white; }
  .list-items .button-activate {
    margin: 6px;
    background-color: green;
    color: white; }
  .list-items .ant-table-footer {
    font-weight: bold; }
  .list-items .cover-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: "100%";
    height: 340px;
    /* Ajusta la altura de tu contenedor 460 */
    overflow: hidden;
    background-color: white;
    /* Color de fondo opcional */ }
    .list-items .cover-container .avatar {
      position: absolute;
      top: 100px;
      left: 10px;
      /* Ajusta la posición horizontal del avatar */
      z-index: 1;
      /* Asegura que el avatar esté encima de la imagen */
      width: 200px;
      height: 200px; }
      .list-items .cover-container .avatar .button-edit-avatar {
        position: absolute;
        bottom: 50px;
        /* Ajusta la posición vertical del avatar */
        left: 10px;
        /* Ajusta la posición horizontal del avatar */
        z-index: 1;
        /* Asegura que el avatar esté encima de la imagen */ }
    .list-items .cover-container .avatar img {
      width: 100%;
      height: auto; }
  .list-items .cover-image {
    object-fit: cover;
    /* Asegura que la imagen cubra el contenedor */
    width: 100%;
    height: 100%; }
  .list-items .upload-button {
    position: absolute;
    bottom: 100px;
    /* Ajusta la posición vertical del botón de subida */
    right: 40px;
    /* Ajusta la posición horizontal del botón de subida */
    z-index: 1;
    /* Asegura que el botón esté encima de la imagen */
    background-color: white; }
  @supports (object-fit: cover) {
    .list-items .box img {
      height: 100%;
      object-fit: cover;
      object-position: center center; } }
  .list-items .button-edit {
    margin: 6px;
    background-image: linear-gradient(to right, #007bff, #4ab2e2);
    color: white; }
  .list-items .button-available {
    margin: 6px;
    background-image: linear-gradient(to right, #6c757d, #adb5bd);
    color: white; }
  .list-items .button-add {
    margin: 6px;
    background-image: linear-gradient(to right, #ffb200, #ffda4e);
    color: white; }
  .list-items .button-delete {
    margin: 6px;
    background-image: linear-gradient(to right, #dc3545, #e0666e);
    color: white; }
  .list-items .ant-table-footer {
    font-weight: bold; }
  .list-items .ant-collapse {
    border-color: "#ff7640";
    background-image: linear-gradient(to left, #a9b5c0, #d5e0eb);
    border-radius: 8px; }
  .list-items .ant-panel .ant-collapse-header {
    font-weight: bold;
    font-size: 16px;
    font-family: sans-serif; }
  .list-items label {
    font-weight: 600; }
  .list-items .ant-input {
    margin-top: 5px; }
