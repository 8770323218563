.list-movements {
     align-items: left;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &-search_date{
            display: flex;
            align-items: left;

            > button {
                margin-right: 20px;
            }
        }

    }
    &__search{
        margin-bottom: 20px;
    }
    .button-movement{
        margin: 6px;
    }
 
}
