.add-paymentorder-form {
    .form-add {
        text-align: left;

        .ant-form-item {
            margin-top: 5px;
            margin-bottom: 2px;

            i {
                color: rgba(0, 0, 0, 0.25);
            }
        }

        .btn-submit {
            margin-top: 10px;
            width: 100%;
        }
        label {
            text-align: left;
        }
        .select-form {
            text-align: left;
        }
        .control-label {
            color: blue;
            text-align: left;
        }
        .control-required{
            color:red;
            text-align: left;
        }
        .ant-input-disabled {
            color:black;
            background-color: white}
    }
}