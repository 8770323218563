.list-paymentmethods {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-switch {
            display: flex;
            align-items: center;

            > button {
                margin-right: 20px;
            }
        }
    }

    &__search{
        display:block;
        margin-bottom: 30px;
    }

    .button-paymentmethod{
        margin: 6px;
    }
}