.edit-item-form {

    .form-edit {
      text-align: left;
  
      .ant-form-item {
        margin-top: 5px;
        margin-bottom: 8px;
  
        i {
          color: rgba(0, 0, 0, 0.25);
        }
  
      }


      label{
        font-weight: 600;
      }

      .ant-input{
        border-radius: 5px;
      }
      .ant-input-disabled {
        color:black;
        background-color:white
    }
      .btn-submit {
        margin-top: 10px;
        width: 100%;
      }
      .select-form {
        width: 100%;
        text-align: left;
      }
      label {
        text-align: left;
      }
      .control-label {
        color: blue;
        text-align: left;
      }
      .control-required {
        color: red;
        text-align: left;
      }
      .button-edit{
        margin: 6px;
        //background-color: rgb(0, 102, 255);
        background-image: linear-gradient(to right, #007bff, #4ab2e2);
        color: white;
    }
    .button-available{
        margin: 6px;
        background-image: linear-gradient(to right, #6c757d, #adb5bd);
        color: white;
    }
    .button-add{
        margin: 6px;
        background-image: linear-gradient(to right, #ffb200, #ffda4e);
        color: white;
    }
    .button-delete{
        margin: 6px;
        background-image: linear-gradient(to right, #dc3545, #e0666e);
        color: white;
    }
    
    }
   
  }
  