.list-clients {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-switch {
            display: flex;
            align-items: center;

            > button {
                margin-right: 20px;
            }
        }
    }

    &__search{
        display:block;
        margin-bottom: 20px;
    }

    .clients-active {
        background-color: #fff;
        padding: 10px 20px;

        .ant-list-item-meta {
            display: flex;
        }
    }
}