.edit-state-form {

    .form-edit {
        text-align: left;

        .ant-form-state {
            margin-top: 5px;
            margin-bottom: 8px;

            i {
                color: rgba(0,0,0,0.25);
            }
        }

        .btn-submit {
            width: 100%;
        }
        .select-form {
            width: 100%;
            text-align: left;
        }
        label {
            text-align: left;
        }
        .control-label {
            color: blue;
            text-align: left;
        }
    }
    .bg-success {
        background:#70C040;
        border-color: #70C040;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .bg-warning {
        background-color: #EFAF41;
        border-color: #EFAF41;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
}
.h4-unpaid {
    color: red;
    text-align: left;
}
.h4-paid {
    color: green;
    text-align: left;
}