.list-merchandiseorders {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-switch {
            display: flex;
            align-items: center;

            > button {
                margin-right: 20px;
            }
        }
    }

    &__search{
        display:block;
        margin-bottom: 50px;
    }

    .hover {
        position: relative;

        &:hover &__no-hover {
            opacity: 0;
        }

        &:hover &__hover {
            opacity: 1;
        }

        &__hover {
            position: absolute;
            top: 0;
            opacity: 0;
        }

        &__no-hover {
            opacity: 1;
        }
    }

    .button-buyorder{
        margin: 6px;
    }
    .bg-success {
        color: #fff;
        background:#70C040;
        border-color: #70C040;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .bg-warning {
        background-color: #EFAF41;
        border-color: #EFAF41;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }

    .buyorders {
        background-color: #fff;
        padding: 10px 20px;

        .ant-list-item-meta {
            display: flex;
        }
    }
    
}