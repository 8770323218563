body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.edit-user-form .upload-avatar {
  display: table;
  margin: 0 auto;
  border: 2px solid #9a9a9a;
  border-style: dashed;
  border-radius: 100px;
  padding: 10px;
  margin-bottom: 20px; }

.edit-user-form .form-edit {
  text-align: center; }
  .edit-user-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .edit-user-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-user-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-user-form .form-edit .select-form {
    width: 100%;
    text-align: left; }

.ant-dropdown-button {
  width: 110px; }
  .ant-dropdown-button .ant-dropdown-button > button {
    width: 110px; }
  .ant-dropdown-button .ant-dropdown-button > button > span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left; }

.menu-top {
  display: flex; }
  .menu-top button {
    color: white;
    padding-left: 0em; }
    .menu-top button:hover {
      color: #0098d3; }
    .menu-top button:focus, .menu-top button:active {
      color: #4ec0ec; }
  .menu-top__left {
    background-image: url(/static/media/azul1.a85c0bc8.png);
    color: white; }
    .menu-top__left-logo {
      width: 20%;
      padding: 0 10px 10px 10px;
      color: white; }
  .menu-top__pre_right_business {
    position: absolute;
    right: 0;
    padding-right: 500px; }
  .menu-top__pre_right_branch {
    position: absolute;
    right: 0;
    padding-right: 280px; }
  .menu-top__pre_right {
    position: absolute;
    right: 0;
    width: 180px;
    padding-left: 30px;
    background: transparent; }
  .menu-top__right {
    position: absolute;
    right: 0;
    width: 60px; }

.admin-sider {
  background-image: url(/static/media/azul1.a85c0bc8.png);
  left: 0;
  height: 100vh;
  padding-top: 4%;
  border-bottom-right-radius: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 1;
  /*
    .ant-menu{
        background-image: url('../../../assets/img/png/azul1.png');
        background-color: white;
        color: white;
    }
    */ }
  .admin-sider button {
    padding-left: 0em;
    color: white; }
    .admin-sider button:hover {
      color: #0098d3; }
    .admin-sider button:focus, .admin-sider button:active {
      color: black; }
  .admin-sider__sider {
    position: fixed;
    left: 0;
    width: 50%;
    display: flex;
    padding: 0;
    height: 220px;
    background-color: white;
    border-bottom-right-radius: 50px;
    color: white; }

.layout-init {
  background: white;
  color: white;
  padding: 0; }

#scroll-y {
  overflow-y: scroll;
  overflow-x: hidden; }

#scroll-x {
  overflow-x: scroll; }

#scroll {
  overflow: scroll; }

#scroll-b {
  scroll-behavior: initial; }

#scroll-hidden {
  overflow-y: hidden;
  overflow-x: hidden; }

.side-nav {
  background-image: url(/static/media/azul1.a85c0bc8.png);
  padding-top: 1.5rem; }

.side-nav ul {
  margin: 0;
  padding: 0; }

.side-nav ul li a {
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block; }

/*
button {
    //background-image: url('../../../assets/img/png/light.png');
    color: white;
    padding-left: 0em;
    //border: 0;

    &:hover {
        //color: $primary-color;
        color: black;
    }
    &:focus,
    &:active {
        //color: $primary-color-hover;
        color: black;
    }
}
*/
.nac-text {
  color: #4ec0ec;
  /*
    &:hover {
        //color: $primary-color;
        color: black;
    }
    &:focus,
    &:active {
        //color: $primary-color-hover;
        color: black;
    }
    */ }
  .nac-text:hover {
    color: black; }
  .nac-text:focus, .nac-text:active {
    color: black; }

/*
.nac-text {
    color: black;
}

.menu-top-web__item {
    color: black;
}
*/

.register-form {
  text-align: left; }
  .register-form .ant-form.item:nth-child(3), .register-form .ant-form.item:last-of-type {
    margin: 0; }
  .register-form__input .ant-input-prefix i {
    font-size: 1.375em; }
  .register-form__input input {
    font-size: 1.25em;
    padding: 2px 2px 2px 4px !important; }
    .register-form__input input:focus {
      border-color: #0098d3;
      box-shadow: none; }
  .register-form__button {
    width: 100%;
    font-size: 1.375em;
    background-color: #0098d3;
    color: #fff;
    height: 42px; }
    .register-form__button:hover {
      background-color: #4ec0ec;
      color: #fff; }
    .register-form__button:focus, .register-form__button:active {
      background-color: #0098d3;
      color: #fff;
      border: 0; }

.login-form .ant-form-item:last-of-type {
  margin: 0; }

.login-form__input .ant-input-prefix i {
  form-size: 1.375em; }

.login-form__input input {
  font-size: 1.25em;
  padding: 2px 2px 2px 4px !important; }
  .login-form__input input:focus {
    border-color: #0098d3;
    box-shadow: none; }

.login-form__button {
  width: 100%;
  font-size: 1.375em;
  background-color: #0098d3;
  color: #fff;
  height: 42px;
  margin-top: 20px; }
  .login-form__button:hover {
    background-color: #4ec0ec;
    color: #fff; }
  .login-form__button:focus, .login-form__button:active {
    background-color: #0098d3;
    color: #fff;
    border: 0; }

.login-form__recover {
  width: 100%;
  font-size: 1.375em;
  color: #0098d3;
  height: 42px;
  margin-top: 20px; }
  .login-form__recover:hover {
    color: blue; }
  .login-form__recover:focus, .login-form__recover:active {
    color: blue; }

.recover-form {
  text-align: left; }
  .recover-form .ant-form-item:last-of-type {
    margin: 0; }
  .recover-form__input .ant-input-prefix i {
    form-size: 1.375em; }
  .recover-form__input input {
    font-size: 1.25em;
    padding: 2px 2px 2px 4px !important; }
    .recover-form__input input:focus {
      border-color: #0098d3;
      box-shadow: none; }
  .recover-form__button {
    width: 100%;
    font-size: 1.375em;
    background-color: #0098d3;
    color: #fff;
    height: 42px;
    margin-top: 20px; }
    .recover-form__button:hover {
      background-color: #4ec0ec;
      color: #fff; }
    .recover-form__button:focus, .recover-form__button:active {
      background-color: #0098d3;
      color: #fff;
      border: 0; }
  .recover-form__recover {
    width: 100%;
    font-size: 1.375em;
    color: #0098d3;
    height: 42px;
    margin-top: 20px; }
    .recover-form__recover:hover {
      color: blue; }
    .recover-form__recover:focus, .recover-form__recover:active {
      color: blue; }
  .recover-form .control-label {
    width: 100%;
    color: black;
    text-align: left;
    font-size: 1.125em; }

.sign-in {
  background-image: url(/static/media/azul3.c971e23b.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .sign-in__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh; }
    .sign-in__content-logo {
      padding: 10px 210px; }
      .sign-in__content-logo img {
        width: 100%; }
    .sign-in__content-tabs {
      background-color: #e8e8e8; }
      .sign-in__content-tabs .ant-tabs-nav {
        width: 100% !important; }
      .sign-in__content-tabs .ant-tabs-tab {
        display: block;
        flex: 1 1;
        text-align: center;
        font-size: 1.1875em;
        border-top: 4px solid #808090 !important;
        border-radius: 0 !important;
        margin: 0 !important; }
        @media (min-width: 992px) {
          .sign-in__content-tabs .ant-tabs-tab {
            width: 220px;
            font-size: 1.4375em; } }
        .sign-in__content-tabs .ant-tabs-tab:hover {
          color: #808088; }
        .sign-in__content-tabs .ant-tabs-tab:before {
          content: none; }
        .sign-in__content-tabs .ant-tabs-tab span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
      .sign-in__content-tabs .ant-tabs-nav > div:nth-of-type(1) {
        display: unset !important;
        width: 100% !important; }
      .sign-in__content-tabs .ant-tabs-nav-container {
        height: 60px !important; }
      .sign-in__content-tabs .ant-tabs-tab-active {
        color: #0098d3 !important;
        border-top: 4px solid #0098d3 !important;
        border-radius: 0 !important;
        border-right: 0 !important;
        border-left: 0 !important; }
      .sign-in__content-tabs .ant-tabs-content {
        width: 100%;
        padding: 0 16px 16px 16px;
        text-align: center; }

.layout-init {
  background: white;
  padding: 0;
  color: white; }

.layout-admin {
  transition: margin-left 0.2s;
  margin: 0 auto;
  color: white; }
  .layout-admin__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0;
    height: 65px;
    background-image: url(/static/media/azul1.a85c0bc8.png);
    border-bottom-right-radius: 50px;
    color: white; }
  .layout-admin__sider {
    position: fixed;
    left: 0;
    width: 50%;
    display: flex;
    padding: 0;
    height: 220px;
    background-image: url(/static/media/azul1.a85c0bc8.png);
    border-bottom-right-radius: 50px;
    color: white;
    margin-top: 300px; }
  .layout-admin__content {
    min-height: 88vh;
    padding: 90px 30px 0px 0px;
    background: white;
    overflow-y: visible;
    overflow-y: initial;
    color: white; }
  .layout-admin__footer {
    background-color: white;
    background-position: center center;
    bottom: 0;
    padding: 8px;
    width: 100%;
    text-align: center;
    size: 25px;
    color: gray;
    font-weight: bold;
    position: static;
    /*margin: 20px;*/ }

#scroll-y {
  overflow-y: auto;
  overflow-x: hidden; }

#scroll-hidden {
  overflow-y: hidden;
  overflow-x: hidden; }

.side-nav {
  background-color: aqua;
  padding-top: 1.5rem;
  color: white; }

.side-nav ul {
  margin: 0;
  padding: 0;
  color: white; }

.side-nav ul li a {
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block; }

.reset-form {
  text-align: left; }
  .reset-form .ant-form-item:last-of-type {
    margin: 0; }
  .reset-form__input .ant-input-prefix i {
    font-size: 1.375em; }
  .reset-form__input input {
    font-size: 1.25em;
    padding: 2px 2px 2px 4px !important; }
    .reset-form__input input:focus {
      border-color: #0098d3;
      box-shadow: none; }
  .reset-form__button {
    width: 100%;
    font-size: 1.375em;
    background-color: #0098d3;
    color: #fff;
    height: 42px;
    margin-top: 20px;
    align-items: center; }
    .reset-form__button:hover {
      background-color: #4ec0ec;
      color: #fff; }
    .reset-form__button:focus, .reset-form__button:active {
      background-color: #0098d3;
      color: #fff;
      border: 0; }
  .reset-form__reset {
    width: 100%;
    font-size: 1.375em;
    color: #0098d3;
    height: 42px;
    margin-top: 20px; }
    .reset-form__reset:hover {
      color: blue; }
    .reset-form__reset:focus, .reset-form__reset:active {
      color: blue; }
  .reset-form .control-label {
    width: 100%;
    color: black;
    text-align: left;
    font-size: 1.125em; }

.reset-form {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .reset-form__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh; }
    .reset-form__content-logo {
      margin-right: 40px; }
      .reset-form__content-logo img {
        width: 100%; }
    .reset-form__content-tabs {
      background-color: #e8e8e8; }
      .reset-form__content-tabs .ant-tabs {
        width: 90vw; }
        @media (min-width: 992px) {
          .reset-form__content-tabs .ant-tabs {
            width: auto; } }
      .reset-form__content-tabs .ant-tabs-nav-container {
        height: 60px !important; }
      .reset-form__content-tabs .ant-tabs-nav {
        width: 100%; }
      .reset-form__content-tabs .ant-tabs-tab {
        width: 50px;
        height: 60px !important;
        text-align: center;
        align-items: center;
        font-size: 1.1875em;
        border-top: 4px solid #808090 !important;
        border-radius: 0 !important;
        margin: 0 !important; }
        @media (min-width: 992px) {
          .reset-form__content-tabs .ant-tabs-tab {
            width: 220px;
            font-size: 1.4375em; } }
        .reset-form__content-tabs .ant-tabs-tab:hover {
          color: #808088; }
        .reset-form__content-tabs .ant-tabs-tab:before {
          content: none; }
        .reset-form__content-tabs .ant-tabs-tab span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
      .reset-form__content-tabs .ant-tabs-tab-active {
        color: #0098d3 !important;
        border-top: 4px solid #0098d3 !important;
        border-radius: 0 !important;
        border-right: 0 !important;
        border-left: 0 !important; }
      .reset-form__content-tabs .ant-tabs-content {
        padding: 0 16px 16px 16px;
        text-align: center; }
  .reset-form__content-error {
    display: flex;
    text-align: center;
    color: black;
    font-size: large;
    align-items: center; }

.layout-basic {
  transition: margin-left 0.2s;
  background-image: url(/static/media/azul1.a85c0bc8.png); }
  .layout-basic__header {
    overflow: hidden;
    /* code added to prevent scroll */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0;
    height: 65px;
    background-image: url(/static/media/azul1.a85c0bc8.png); }
  .layout-basic__content {
    min-height: calc(100vh - 65px);
    padding: 90px 25px 25px 25px; }
  .layout-basic__footer {
    overflow: hidden;
    /* code added to prevent scroll */
    background-color: white;
    background-position: center center;
    background-size: cover;
    padding: 20px;
    width: 100%;
    text-align: center;
    size: 25px;
    color: gray;
    font-weight: bold;
    /*margin: 20px;*/ }

#scroll-y {
  overflow-y: auto;
  overflow-x: hidden; }

#scroll-hidden {
  overflow-y: hidden;
  overflow-x: hidden; }

.add-user-form .form-add {
  text-align: left; }
  .add-user-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-user-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-user-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-user-form .form-add .select-form {
    text-align: left; }

.add-user-form .control-label {
  color: blue;
  text-align: left; }

.add-user-form .control-required {
  color: red;
  text-align: left; }

.list-users__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-users__header-switch {
    display: flex;
    align-items: center; }
    .list-users__header-switch > button {
      margin-right: 20px; }

.list-users .users-active {
  background-color: #fff;
  padding: 10px 20px; }
  .list-users .users-active .ant-list-item-meta {
    display: flex; }

.list-users .boton-export {
  align-items: right; }

.boton-user {
  margin-top: 5px;
  align-items: right; }

.button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.button-activate {
  margin: 6px;
  background-color: green;
  color: white; }


.list-exports__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-exports__header-switch {
    display: flex;
    align-items: center; }
    .list-exports__header-switch > button {
      margin-right: 20px; }

.list-exports__search {
  display: block;
  margin-bottom: 30px; }

.list-exports .button-export {
  margin: 6px; }

.list-exports .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }


.list-exports__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-exports__header-switch {
    display: flex;
    align-items: center; }
    .list-exports__header-switch > button {
      margin-right: 20px; }

.list-exports__search {
  display: block;
  margin-bottom: 30px; }

.list-exports .button-export {
  margin: 6px; }

.list-exports .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-menu__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px; }

.list-menu__items {
  background-color: #fff;
  padding: 10px 20px; }
  .list-menu__items .item-button {
    margin: 6px; }

.list-menu .row-dragging {
  background: #fafafa;
  border: 1px solid #ccc; }

.list-menu .row-dragging td {
  padding: 16px; }

.list-menu .row-dragging .drag-visible {
  visibility: visible; }

.list-menu .ant-table-footer {
  font-weight: bold; }

.add-menu-form .form-add {
  text-align: left; }
  .add-menu-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-menu-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-menu-form .form-add .btn-submit {
    width: 100%; }
  .add-menu-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-menu-form .form-add .control-required {
    color: red;
    text-align: left; }

.edit-menu-form .form-edit {
  text-align: left; }
  .edit-menu-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .edit-menu-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-menu-form .form-edit .btn-submit {
    width: 100%; }
  .edit-menu-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-menu-form .form-edit .control-required {
    color: red;
    text-align: left; }

.edit-role-form .form-edit {
  text-align: left; }
  .edit-role-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-role-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-role-form .form-edit .btn-submit {
    width: 100%; }
  .edit-role-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-role-form .form-edit label {
    text-align: left; }
  .edit-role-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-role-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-role-form .form-add {
  text-align: left; }
  .add-role-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-role-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-role-form .form-add .btn-submit {
    width: 100%; }
  .add-role-form .form-add label {
    text-align: left; }
  .add-role-form .form-add .select-form {
    text-align: left; }
  .add-role-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-role-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-roles__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-roles__header-switch {
    display: flex;
    align-items: center; }
    .list-roles__header-switch > button {
      margin-right: 20px; }

.list-roles__search {
  display: block;
  margin-bottom: 30px; }

.list-roles .button-role {
  margin: 6px; }

.list-roles .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-roles .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-roles .ant-table-footer {
  font-weight: bold; }

.edit-permission-form .form-edit {
  text-align: left; }
  .edit-permission-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-permission-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-permission-form .form-edit .btn-submit {
    width: 100%; }
  .edit-permission-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-permission-form .form-edit label {
    text-align: left; }
  .edit-permission-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-permission-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-permission-form .form-add {
  text-align: left; }
  .add-permission-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-permission-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-permission-form .form-add .btn-submit {
    width: 100%; }
  .add-permission-form .form-add label {
    text-align: left; }
  .add-permission-form .form-add .select-form {
    text-align: left; }
  .add-permission-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-permission-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-permissions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-permissions__header-switch {
    display: flex;
    align-items: center; }
    .list-permissions__header-switch > button {
      margin-right: 20px; }

.list-permissions__search {
  display: block;
  margin-bottom: 30px; }

.list-permissions .button-permission {
  margin: 6px; }

.list-permissions .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-permissions .ant-table-footer {
  font-weight: bold; }

.edit-day-form .form-edit {
  text-align: left; }
  .edit-day-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-day-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-day-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-day-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-day-form .form-edit label {
    text-align: left; }
  .edit-day-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-day-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-day-form .form-add {
  text-align: left; }
  .add-day-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-day-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-day-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-day-form .form-add label {
    text-align: left; }
  .add-day-form .form-add .select-form {
    text-align: left; }
  .add-day-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-day-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-days__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-days__header-switch {
    display: flex;
    align-items: center; }
    .list-days__header-switch > button {
      margin-right: 20px; }

.list-days__search {
  display: block;
  margin-bottom: 30px; }

.list-days .button-day {
  margin: 6px; }

.list-days .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-days .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-days .ant-table-footer {
  font-weight: bold; }

.edit-duration-form .form-edit {
  text-align: left; }
  .edit-duration-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-duration-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-duration-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-duration-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-duration-form .form-edit label {
    text-align: left; }
  .edit-duration-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-duration-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-duration-form .form-add {
  text-align: left; }
  .add-duration-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-duration-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-duration-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-duration-form .form-add label {
    text-align: left; }
  .add-duration-form .form-add .select-form {
    text-align: left; }
  .add-duration-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-duration-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-durations__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-durations__header-switch {
    display: flex;
    align-items: center; }
    .list-durations__header-switch > button {
      margin-right: 20px; }

.list-durations__search {
  display: block;
  margin-bottom: 30px; }

.list-durations .button-duration {
  margin: 6px; }

.list-durations .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-durations .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-durations .ant-table-footer {
  font-weight: bold; }

.add-edit-branch-form__form {
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 150px;
  margin-left: 80px; }

.add-edit-branch-form .form-addedit {
  text-align: left; }
  .add-edit-branch-form .form-addedit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 20px; }
    .add-edit-branch-form .form-addedit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .add-edit-branch-form .form-addedit .ant-form-item .button-add {
      margin-top: 20px; }
  .add-edit-branch-form .form-addedit .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
    padding-top: 100; }
  .add-edit-branch-form .form-addedit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-edit-branch-form .form-addedit label {
    text-align: left; }
  .add-edit-branch-form .form-addedit .select-form {
    text-align: left; }
  .add-edit-branch-form .form-addedit .control-required {
    color: red;
    text-align: left; }

.list-branches__header {
  display: flex;
  align-items: center;
  color: black;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-branches__header-switch {
    display: flex;
    align-items: center; }
    .list-branches__header-switch > button {
      margin-right: 20px; }

.list-branches__search {
  display: block;
  margin-bottom: 30px; }

.list-branches .button-branch {
  margin: 6px; }

.list-branches .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-branches .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-branches .ant-table-footer {
  font-weight: bold; }

.add-edit-business-form__form {
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 150px;
  margin-left: 80px; }

.add-edit-business-form .form-addedit {
  text-align: left; }
  .add-edit-business-form .form-addedit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 20px; }
    .add-edit-business-form .form-addedit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .add-edit-business-form .form-addedit .ant-form-item .button-add {
      margin-top: 20px; }
  .add-edit-business-form .form-addedit .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
    padding-top: 100; }
  .add-edit-business-form .form-addedit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-edit-business-form .form-addedit label {
    text-align: left; }
  .add-edit-business-form .form-addedit .select-form {
    text-align: left; }
  .add-edit-business-form .form-addedit .control-required {
    color: red;
    text-align: left; }

.add-edit-business-form__form {
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 150px;
  margin-left: 80px; }

.add-edit-business-form .form-addedit {
  text-align: left; }
  .add-edit-business-form .form-addedit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 20px; }
    .add-edit-business-form .form-addedit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .add-edit-business-form .form-addedit .ant-form-item .button-add {
      margin-top: 20px; }
  .add-edit-business-form .form-addedit .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
    padding-top: 100; }
  .add-edit-business-form .form-addedit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-edit-business-form .form-addedit label {
    text-align: left; }
  .add-edit-business-form .form-addedit .select-form {
    text-align: left; }
  .add-edit-business-form .form-addedit .control-required {
    color: red;
    text-align: left; }

.add-edit-branch-form__form {
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 150px;
  margin-left: 80px; }

.add-edit-branch-form .form-addedit {
  text-align: left; }
  .add-edit-branch-form .form-addedit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 20px; }
    .add-edit-branch-form .form-addedit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .add-edit-branch-form .form-addedit .ant-form-item .button-add {
      margin-top: 20px; }
  .add-edit-branch-form .form-addedit .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
    padding-top: 100; }
  .add-edit-branch-form .form-addedit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-edit-branch-form .form-addedit label {
    text-align: left; }
  .add-edit-branch-form .form-addedit .select-form {
    text-align: left; }
  .add-edit-branch-form .form-addedit .control-required {
    color: red;
    text-align: left; }

.edit-item-form .form-edit {
  text-align: left; }
  .edit-item-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-item-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-item-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-item-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-item-form .form-edit label {
    text-align: left; }
  .edit-item-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-item-form .form-edit .control-required {
    color: red;
    text-align: left; }

.edit-variation-category-form .form-edit {
  text-align: left; }
  .edit-variation-category-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-variation-category-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-variation-category-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-variation-category-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-variation-category-form .form-edit label {
    text-align: left; }
  .edit-variation-category-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-variation-category-form .form-edit .control-required {
    color: red;
    text-align: left; }

.edit-category-form .form-edit {
  text-align: left; }
  .edit-category-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-category-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-category-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-category-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-category-form .form-edit label {
    text-align: left; }
  .edit-category-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-category-form .form-edit .control-required {
    color: red;
    text-align: left; }

@charset "UTF-8";
.list-items {
  /* src/components/CoverImage.css */ }
  .list-items__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .list-items__header-switch {
      display: flex;
      align-items: center; }
      .list-items__header-switch > button {
        margin-right: 20px; }
  .list-items__search {
    display: block;
    margin-bottom: 30px; }
  .list-items__categories {
    margin-top: 30px; }
  .list-items label {
    color: black; }
  .list-items .button-specialty {
    margin: 6px; }
  .list-items .button-edit {
    margin: 6px;
    background-color: orange;
    color: white; }
  .list-items .button-activate {
    margin: 6px;
    background-color: green;
    color: white; }
  .list-items .ant-table-footer {
    font-weight: bold; }
  .list-items .cover-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: "100%";
    height: 340px;
    /* Ajusta la altura de tu contenedor 460 */
    overflow: hidden;
    background-color: white;
    /* Color de fondo opcional */ }
    .list-items .cover-container .avatar {
      z-index: 1;
      /* Asegura que el avatar esté encima de la imagen */
      overflow: hidden;
      /* Asegura que el contenido excedente no se muestre */
      max-width: 200px;
      /* Tamaño máximo */
      max-height: 200px;
      /* Tamaño máximo */
      display: flex;
      align-items: center;
      justify-content: center; }
      .list-items .cover-container .avatar .button-edit-avatar {
        position: absolute;
        top: 60px;
        left: 10px;
        /* Ajusta la posición horizontal del avatar */
        z-index: 10;
        /* Asegura que el avatar esté encima de la imagen */ }
      @supports (object-fit: cover) {
        .list-items .cover-container .avatar .box img {
          height: 100%;
          object-fit: cover;
          object-position: center center; } }
    @supports (object-fit: cover) {
      .list-items .cover-container .box img {
        height: 100%;
        object-fit: cover;
        object-position: center center; } }
  .list-items .cover-image {
    object-fit: cover;
    /* Asegura que la imagen cubra el contenedor */
    width: 100%;
    height: 100%; }
  .list-items .upload-button {
    position: absolute;
    bottom: 100px;
    /* Ajusta la posición vertical del botón de subida */
    right: 40px;
    /* Ajusta la posición horizontal del botón de subida */
    z-index: 1;
    /* Asegura que el botón esté encima de la imagen */
    background-color: white; }
  .list-items .button-edit {
    margin: 6px;
    background-image: linear-gradient(to right, #007bff, #4ab2e2);
    color: white; }
  .list-items .button-available {
    margin: 6px;
    background-image: linear-gradient(to right, #6c757d, #adb5bd);
    color: white; }
  .list-items .button-add {
    margin: 6px;
    background-image: linear-gradient(to right, #ffb200, #ffda4e);
    color: white; }
  .list-items .button-delete {
    margin: 6px;
    background-image: linear-gradient(to right, #dc3545, #e0666e);
    color: white; }
  .list-items .ant-table-footer {
    font-weight: bold; }
  .list-items .ant-collapse {
    border-color: "#ff7640";
    background-image: linear-gradient(to left, #a9b5c0, #d5e0eb);
    border-radius: 8px; }
  .list-items .ant-panel .ant-collapse-header {
    font-weight: bold;
    font-size: 16px;
    font-family: sans-serif; }
  .list-items .my-row {
    margin-top: 15px;
    /* Ajusta el valor según sea necesario */ }


.add-category-form .form-add {
  text-align: left; }
  .add-category-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-category-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-category-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-category-form .form-add label {
    text-align: left; }
  .add-category-form .form-add .select-form {
    text-align: left; }
  .add-category-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-category-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-categories__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-categories__header-switch {
    display: flex;
    align-items: center; }
    .list-categories__header-switch > button {
      margin-right: 20px; }

.list-categories__search {
  display: block;
  margin-bottom: 30px; }

.list-categories .button-category {
  margin: 6px; }

.list-categories .button-edit {
  margin: 6px;
  background-image: linear-gradient(to right, #007bff, #4ab2e2);
  color: white; }

.list-categories .button-available {
  margin: 6px;
  background-image: linear-gradient(to right, #6c757d, #adb5bd);
  color: white; }

.list-categories .button-add {
  margin: 6px;
  background-image: linear-gradient(to right, #ffb200, #ffda4e);
  color: white; }

.list-categories .button-delete {
  margin: 6px;
  background-image: linear-gradient(to right, #dc3545, #e0666e);
  color: white; }

.list-categories .ant-table-footer {
  font-weight: bold; }

.list-categories .ant-collapse {
  border-color: "#ff7640";
  background-image: linear-gradient(to left, #a9b5c0, #d5e0eb);
  border-radius: 8px; }

.list-categories .ant-panel .ant-collapse-header {
  font-weight: bold;
  font-size: 16px;
  font-family: sans-serif; }

.list-branch-categories__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-branch-categories__header-switch {
    display: flex;
    align-items: center; }
    .list-branch-categories__header-switch > button {
      margin-right: 20px; }

.list-branch-categories__search {
  display: block;
  margin-bottom: 30px; }

.list-branch-categories .button-edit {
  margin: 6px;
  background-image: linear-gradient(to right, #007bff, #4ab2e2);
  color: white; }

.list-branch-categories .button-available {
  margin: 6px;
  background-image: linear-gradient(to right, #6c757d, #adb5bd);
  color: white; }

.list-branch-categories .button-add {
  margin: 6px;
  background-image: linear-gradient(to right, #ffb200, #ffda4e);
  color: white; }

.list-branch-categories .button-delete {
  margin: 6px;
  background-image: linear-gradient(to right, #dc3545, #e0666e);
  color: white; }

.list-branch-categories .ant-table-footer {
  font-weight: bold; }

.list-branch-categories .ant-collapse {
  border-color: "#ff7640";
  background-image: linear-gradient(to left, #a9b5c0, #d5e0eb);
  border-radius: 8px; }

.list-branch-categories .ant-panel .ant-collapse-header {
  font-weight: bold;
  font-size: 16px;
  font-family: sans-serif; }

.edit-variation-form .form-edit {
  text-align: left; }
  .edit-variation-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-variation-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-variation-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-variation-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-variation-form .form-edit label {
    text-align: left; }
  .edit-variation-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-variation-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-variation-form .form-add {
  text-align: left; }
  .add-variation-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-variation-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-variation-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-variation-form .form-add label {
    text-align: left; }
  .add-variation-form .form-add .select-form {
    text-align: left; }
  .add-variation-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-variation-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-variations__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-variations__header-switch {
    display: flex;
    align-items: center; }
    .list-variations__header-switch > button {
      margin-right: 20px; }

.list-variations__search {
  display: block;
  margin-bottom: 30px; }

.list-variations .button-variation {
  margin: 6px; }

.list-variations .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-variations .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-variations .ant-table-footer {
  font-weight: bold; }


.list-variations__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-variations__header-switch {
    display: flex;
    align-items: center; }
    .list-variations__header-switch > button {
      margin-right: 20px; }

.list-variations__search {
  display: block;
  margin-bottom: 30px; }

.list-variations .button-variation {
  margin: 6px; }

.list-variations .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-variations .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-variations .ant-table-footer {
  font-weight: bold; }

.add-variation-category-form .form-add {
  text-align: left; }
  .add-variation-category-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-variation-category-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-variation-category-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-variation-category-form .form-add label {
    text-align: left; }
  .add-variation-category-form .form-add .select-form {
    text-align: left; }
  .add-variation-category-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-variation-category-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-variation-categories__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-variation-categories__header-switch {
    display: flex;
    align-items: center; }
    .list-variation-categories__header-switch > button {
      margin-right: 20px; }

.list-variation-categories__search {
  display: block;
  margin-bottom: 30px; }

.list-variation-categories .button-variation {
  margin: 6px; }

.list-variation-categories .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-variation-categories .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-variation-categories .ant-table-footer {
  font-weight: bold; }


.edit-item-form .form-edit {
  text-align: left; }
  .edit-item-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-item-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-item-form .form-edit label {
    font-weight: 600; }
  .edit-item-form .form-edit .ant-input {
    border-radius: 5px; }
  .edit-item-form .form-edit .ant-input-disabled {
    color: black;
    background-color: white; }
  .edit-item-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-item-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-item-form .form-edit label {
    text-align: left; }
  .edit-item-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-item-form .form-edit .control-required {
    color: red;
    text-align: left; }
  .edit-item-form .form-edit .button-edit {
    margin: 6px;
    background-image: linear-gradient(to right, #007bff, #4ab2e2);
    color: white; }
  .edit-item-form .form-edit .button-available {
    margin: 6px;
    background-image: linear-gradient(to right, #6c757d, #adb5bd);
    color: white; }
  .edit-item-form .form-edit .button-add {
    margin: 6px;
    background-image: linear-gradient(to right, #ffb200, #ffda4e);
    color: white; }
  .edit-item-form .form-edit .button-delete {
    margin: 6px;
    background-image: linear-gradient(to right, #dc3545, #e0666e);
    color: white; }

@charset "UTF-8";
.list-items {
  /* src/components/CoverImage.css */ }
  .list-items__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .list-items__header-switch {
      display: flex;
      align-items: center; }
      .list-items__header-switch > button {
        margin-right: 20px; }
  .list-items .my-row {
    margin-top: 15px;
    /* Ajusta el valor según sea necesario */ }
  .list-items__search {
    display: block;
    margin-bottom: 30px; }
  .list-items__categories {
    margin-top: 30px; }
  .list-items label {
    color: black; }
  .list-items .button-specialty {
    margin: 6px; }
  .list-items .button-edit {
    margin: 6px;
    background-color: orange;
    color: white; }
  .list-items .button-activate {
    margin: 6px;
    background-color: green;
    color: white; }
  .list-items .ant-table-footer {
    font-weight: bold; }
  .list-items .cover-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: "100%";
    height: 340px;
    /* Ajusta la altura de tu contenedor 460 */
    overflow: hidden;
    background-color: white;
    /* Color de fondo opcional */ }
    .list-items .cover-container .avatar {
      position: absolute;
      top: 100px;
      left: 10px;
      /* Ajusta la posición horizontal del avatar */
      z-index: 1;
      /* Asegura que el avatar esté encima de la imagen */
      width: 200px;
      height: 200px; }
      .list-items .cover-container .avatar .button-edit-avatar {
        position: absolute;
        bottom: 50px;
        /* Ajusta la posición vertical del avatar */
        left: 10px;
        /* Ajusta la posición horizontal del avatar */
        z-index: 1;
        /* Asegura que el avatar esté encima de la imagen */ }
    .list-items .cover-container .avatar img {
      width: 100%;
      height: auto; }
  .list-items .cover-image {
    object-fit: cover;
    /* Asegura que la imagen cubra el contenedor */
    width: 100%;
    height: 100%; }
  .list-items .upload-button {
    position: absolute;
    bottom: 100px;
    /* Ajusta la posición vertical del botón de subida */
    right: 40px;
    /* Ajusta la posición horizontal del botón de subida */
    z-index: 1;
    /* Asegura que el botón esté encima de la imagen */
    background-color: white; }
  @supports (object-fit: cover) {
    .list-items .box img {
      height: 100%;
      object-fit: cover;
      object-position: center center; } }
  .list-items .button-edit {
    margin: 6px;
    background-image: linear-gradient(to right, #007bff, #4ab2e2);
    color: white; }
  .list-items .button-available {
    margin: 6px;
    background-image: linear-gradient(to right, #6c757d, #adb5bd);
    color: white; }
  .list-items .button-add {
    margin: 6px;
    background-image: linear-gradient(to right, #ffb200, #ffda4e);
    color: white; }
  .list-items .button-delete {
    margin: 6px;
    background-image: linear-gradient(to right, #dc3545, #e0666e);
    color: white; }
  .list-items .ant-table-footer {
    font-weight: bold; }
  .list-items .ant-collapse {
    border-color: "#ff7640";
    background-image: linear-gradient(to left, #a9b5c0, #d5e0eb);
    border-radius: 8px; }
  .list-items .ant-panel .ant-collapse-header {
    font-weight: bold;
    font-size: 16px;
    font-family: sans-serif; }
  .list-items label {
    font-weight: 600; }
  .list-items .ant-input {
    margin-top: 5px; }


.list-businesses__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-businesses__header-switch {
    display: flex;
    align-items: center; }
    .list-businesses__header-switch > button {
      margin-right: 20px; }

.list-businesses__search {
  display: block;
  margin-bottom: 30px; }

.list-businesses .button-business {
  margin: 6px; }

.list-businesses .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-businesses .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-businesses .ant-table-footer {
  font-weight: bold; }

.edit-supplier-form .upload-avatar {
  display: table;
  margin: 0 auto;
  border: 2px solid #9a9a9a;
  border-style: dashed;
  border-radius: 100px;
  padding: 6px;
  margin-bottom: 10px; }

.edit-supplier-form .form-edit {
  text-align: left; }
  .edit-supplier-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .edit-supplier-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-supplier-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-supplier-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-supplier-form .form-edit label {
    text-align: left; }
  .edit-supplier-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-supplier-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-supplier-form .form-add {
  text-align: left; }
  .add-supplier-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-supplier-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-supplier-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-supplier-form .form-add .select-form {
    text-align: left; }
  .add-supplier-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-supplier-form .form-add .control-required {
    color: red;
    text-align: left; }

.control-label {
  color: blue;
  text-align: left; }

.control-required {
  color: red;
  text-align: left; }

.list-suppliers__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-suppliers__header-switch {
    display: flex;
    align-items: center; }
    .list-suppliers__header-switch > button {
      margin-right: 20px; }

.list-suppliers__search {
  display: block;
  margin-bottom: 20px; }

.list-suppliers .suppliers-active {
  background-color: #fff;
  padding: 10px 20px; }
  .list-suppliers .suppliers-active .ant-list-item-meta {
    display: flex; }


.edit-client-form .upload-avatar {
  display: table;
  margin: 0 auto;
  border: 2px solid #9a9a9a;
  border-style: dashed;
  border-radius: 100px;
  padding: 10px;
  margin-bottom: 20px; }

.edit-client-form .form-edit {
  text-align: left; }
  .edit-client-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .edit-client-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-client-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-client-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-client-form .form-edit label {
    text-align: left; }
  .edit-client-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-client-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-client-form .form-add {
  text-align: left; }
  .add-client-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-client-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-client-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-client-form .form-add label {
    text-align: left; }
  .add-client-form .form-add .select-form {
    text-align: left; }
  .add-client-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-client-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-clients__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-clients__header-switch {
    display: flex;
    align-items: center; }
    .list-clients__header-switch > button {
      margin-right: 20px; }

.list-clients__search {
  display: block;
  margin-bottom: 20px; }

.list-clients .clients-active {
  background-color: #fff;
  padding: 10px 20px; }
  .list-clients .clients-active .ant-list-item-meta {
    display: flex; }


.edit-buybill-form .form-edit {
  text-align: left; }
  .edit-buybill-form .form-edit .ant-form-item {
    margin-top: 2px;
    margin-bottom: 5px; }
    .edit-buybill-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .edit-buybill-form .form-edit .ant-form-item .button-add {
      margin-top: 20px; }
  .edit-buybill-form .form-edit .btn-submit {
    width: 100%; }
  .edit-buybill-form .form-edit label {
    text-align: left; }
  .edit-buybill-form .form-edit .select-form {
    text-align: left; }
  .edit-buybill-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-buybill-form .form-edit .control-required {
    color: red;
    text-align: left; }
  .edit-buybill-form .form-edit .ant-table-body {
    min-height: 150px; }
  .edit-buybill-form .form-edit .ant-input-disabled {
    color: black;
    background-color: white; }

.edit-buybill-form .bg-success {
  background: #70C040;
  border-color: #70C040;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.edit-buybill-form .bg-warning {
  background-color: #EFAF41;
  border-color: #EFAF41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.edit-state-form .form-edit {
  text-align: left; }
  .edit-state-form .form-edit .ant-form-state {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-state-form .form-edit .ant-form-state i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-state-form .form-edit .btn-submit {
    width: 100%; }
  .edit-state-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-state-form .form-edit label {
    text-align: left; }
  .edit-state-form .form-edit .control-label {
    color: blue;
    text-align: left; }

.h4-unpaid {
  color: red;
  text-align: left; }

.h4-paid {
  color: green;
  text-align: left; }

.bg-success {
  color: #fff;
  background: #70C040;
  border-color: #70C040;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.bg-warning {
  background-color: #EFAF41; }

.view-buybill-form .form-view {
  text-align: left; }
  .view-buybill-form .form-view .ant-form-buybill {
    margin-top: 5px;
    margin-bottom: 8px; }
    .view-buybill-form .form-view .ant-form-buybill i {
      color: rgba(0, 0, 0, 0.25); }
  .view-buybill-form .form-view .btn-submit {
    width: 100%; }
  .view-buybill-form .form-view .select-form {
    width: 100%;
    text-align: left; }
  .view-buybill-form .form-view label {
    text-align: left;
    font-weight: bold;
    color: blue; }
  .view-buybill-form .form-view .control-label {
    color: blue;
    text-align: left; }
  .view-buybill-form .form-view .title {
    text-align: left;
    font-weight: bold; }
  .view-buybill-form .form-view .normal {
    text-align: left;
    font-weight: normal; }

.add-buybill-form .form-add {
  text-align: left; }
  .add-buybill-form .form-add .ant-form-item {
    margin-top: 2px;
    margin-bottom: 5px; }
    .add-buybill-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .add-buybill-form .form-add .ant-form-item .button-add {
      margin-top: 20px; }
  .add-buybill-form .form-add .btn-submit {
    width: 100%; }
  .add-buybill-form .form-add label {
    text-align: left; }
  .add-buybill-form .form-add .select-form {
    text-align: left; }
  .add-buybill-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-buybill-form .form-add .control-required {
    color: red;
    text-align: left; }
  .add-buybill-form .form-add .ant-table-body {
    min-height: 150px; }
  .add-buybill-form .form-add .ant-input-disabled {
    color: black;
    background-color: white; }

.add-buybill-form .bg-success {
  background: #70c040;
  border-color: #70c040;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.add-buybill-form .bg-warning {
  background-color: #efaf41;
  border-color: #efaf41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.search-date {
  display: flex; }
  .search-date > button {
    width: 100%;
    margin-bottom: 6px; }
  .search-date .button-search {
    left: -6px; }

.list-buybills__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-buybills__header-switch {
    display: flex;
    align-items: center; }
    .list-buybills__header-switch > button {
      margin-right: 20px; }

.list-buybills__search {
  display: block;
  margin-bottom: 50px; }

.list-buybills .hover {
  position: relative; }
  .list-buybills .hover:hover .list-buybills .hover__no-hover {
    opacity: 0; }
  .list-buybills .hover:hover .list-buybills .hover__hover {
    opacity: 1; }
  .list-buybills .hover__hover {
    position: absolute;
    top: 0;
    opacity: 0; }
  .list-buybills .hover__no-hover {
    opacity: 1; }

.list-buybills .button-buybill {
  margin: 6px; }

.list-buybills .bg-success {
  color: #fff;
  background: #70C040;
  border-color: #70C040;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-buybills .bg-warning {
  background-color: #EFAF41;
  border-color: #EFAF41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-buybills .buybill {
  background-color: #fff;
  padding: 10px 20px; }
  .list-buybills .buybill .ant-list-item-meta {
    display: flex; }


.edit-billsell-form .form-edit {
  text-align: left; }
  .edit-billsell-form .form-edit .ant-form-item {
    margin-top: 2px;
    margin-bottom: 2px; }
    .edit-billsell-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .edit-billsell-form .form-edit .ant-form-item .button-add {
      margin-top: 20px; }
  .edit-billsell-form .form-edit .btn-submit {
    width: 100%; }
  .edit-billsell-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-billsell-form .form-edit label {
    text-align: left; }
  .edit-billsell-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-billsell-form .form-edit .control-required {
    color: red;
    text-align: left; }
  .edit-billsell-form .form-edit .ant-table-body {
    min-height: 150px; }
  .edit-billsell-form .form-edit .ant-input-disabled {
    color: black;
    background-color: white; }

.edit-state-form .form-edit {
  text-align: left; }
  .edit-state-form .form-edit .ant-form-state {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-state-form .form-edit .ant-form-state i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-state-form .form-edit .btn-submit {
    width: 100%; }
  .edit-state-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-state-form .form-edit label {
    text-align: left; }
  .edit-state-form .form-edit .control-label {
    color: blue;
    text-align: left; }

.edit-state-form .bg-success {
  background: #70C040;
  border-color: #70C040;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.edit-state-form .bg-warning {
  background-color: #EFAF41;
  border-color: #EFAF41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.h4-unpaid {
  color: red;
  text-align: left; }

.h4-paid {
  color: green;
  text-align: left; }

.view-billsell-form .title {
  text-align: left;
  font-weight: bold; }

.view-billsell-form .form-view {
  text-align: left; }
  .view-billsell-form .form-view .ant-form-billsell {
    margin-top: 5px;
    margin-bottom: 8px; }
    .view-billsell-form .form-view .ant-form-billsell i {
      color: rgba(0, 0, 0, 0.25); }
  .view-billsell-form .form-view .btn-submit {
    width: 100%; }
  .view-billsell-form .form-view .select-form {
    width: 100%;
    text-align: left; }
  .view-billsell-form .form-view label {
    text-align: left; }
  .view-billsell-form .form-view .control-label {
    color: blue;
    text-align: left; }
  .view-billsell-form .form-view .title {
    text-align: left;
    font-weight: bold; }
  .view-billsell-form .form-view .normal {
    text-align: left;
    font-weight: normal; }

.add-billsell-form .form-add {
  text-align: left; }
  .add-billsell-form .form-add .ant-form-item {
    margin-top: 2px;
    margin-bottom: 2px; }
    .add-billsell-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .add-billsell-form .form-add .ant-form-item .button-add {
      margin-top: 20px; }
  .add-billsell-form .form-add .btn-submit {
    width: 100%; }
  .add-billsell-form .form-add label {
    text-align: left; }
  .add-billsell-form .form-add .select-form {
    text-align: left; }
  .add-billsell-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-billsell-form .form-add .control-required {
    color: red;
    text-align: left; }
  .add-billsell-form .form-add .ant-table-body {
    min-height: 150px; }
  .add-billsell-form .form-add .ant-input-disabled {
    color: black;
    background-color: white; }

.add-billsell-form .bg-success {
  background: #70C040;
  border-color: #70C040;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.add-billsell-form .bg-warning {
  background-color: #EFAF41;
  border-color: #EFAF41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-billsells__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-billsells__header-switch {
    display: flex;
    align-items: center; }
    .list-billsells__header-switch > button {
      margin-right: 20px; }

.list-billsells__search {
  display: block;
  margin-bottom: 30px; }

.list-billsells .button-billsell {
  margin: 6px; }

.list-billsells .bg-success {
  background: #70C040;
  border-color: #70C040;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-billsells .bg-warning {
  background-color: #EFAF41;
  border-color: #EFAF41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-billsells .billsell {
  background-color: #fff;
  padding: 10px 20px; }
  .list-billsells .billsell .ant-list-item-meta {
    display: flex; }


.list-inventories__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-inventories__header-switch {
    display: flex;
    align-items: left; }
    .list-inventories__header-switch > button {
      margin-right: 20px; }

.list-inventories__search {
  margin-bottom: 20px; }

.list-inventories .button-inventory {
  margin: 6px; }

.add-stock-inventory-form .form-add {
  text-align: left; }
  .add-stock-inventory-form .form-add .ant-form-buybill {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-stock-inventory-form .form-add .ant-form-buybill i {
      color: rgba(0, 0, 0, 0.25); }
  .add-stock-inventory-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-stock-inventory-form .form-add label {
    text-align: left; }
  .add-stock-inventory-form .form-add .select-form {
    text-align: left; }
  .add-stock-inventory-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-stock-inventory-form .form-add .control-required {
    color: red;
    text-align: left; }

.edit-stock-inventory-form .form-edit {
  text-align: left; }
  .edit-stock-inventory-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-stock-inventory-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .edit-stock-inventory-form .form-edit .ant-form-item input {
      background-color: white;
      color: rgba(0, 0, 0, 0.75); }
  .edit-stock-inventory-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-stock-inventory-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-stock-inventory-form .form-edit label {
    text-align: left; }
  .edit-stock-inventory-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-stock-inventory-form .form-edit .button {
    margin: 6px;
    align-items: right; }


.add-movement-form .form-add {
  text-align: left; }
  .add-movement-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-movement-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-movement-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-movement-form .form-add .select-form {
    text-align: left; }
  .add-movement-form .form-add .button-add {
    margin-top: 20px; }

.add-movement-form .control-label {
  color: blue;
  text-align: left; }

.add-movement-form .control-required {
  color: red;
  text-align: left; }

.edit-movement-form .form-edit {
  text-align: left; }
  .edit-movement-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-movement-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .edit-movement-form .form-edit .ant-form-item input {
      background-color: white;
      color: rgba(0, 0, 0, 0.75); }
  .edit-movement-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-movement-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-movement-form .form-edit label {
    text-align: left; }
  .edit-movement-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-movement-form .form-edit .button {
    margin: 6px;
    align-items: right; }


.list-movements {
  align-items: left; }
  .list-movements__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
    .list-movements__header-search_date {
      display: flex;
      align-items: left; }
      .list-movements__header-search_date > button {
        margin-right: 20px; }
  .list-movements__search {
    margin-bottom: 20px; }
  .list-movements .button-movement {
    margin: 6px; }

.edit-documentType-form .form-edit {
  text-align: left; }
  .edit-documentType-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-documentType-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-documentType-form .form-edit .btn-submit {
    width: 100%; }
  .edit-documentType-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-documentType-form .form-edit label {
    text-align: left; }
  .edit-documentType-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-documentType-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-documentType-form .form-add {
  text-align: left; }
  .add-documentType-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-documentType-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-documentType-form .form-add .btn-submit {
    width: 100%; }
  .add-documentType-form .form-add label {
    text-align: left; }
  .add-documentType-form .form-add .select-form {
    text-align: left; }
  .add-documentType-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-documentType-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-documentTypes__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-documentTypes__header-switch {
    display: flex;
    align-items: center; }
    .list-documentTypes__header-switch > button {
      margin-right: 20px; }

.list-documentTypes__search {
  display: block;
  margin-bottom: 30px; }

.list-documentTypes .button-documentType {
  margin: 6px; }

.edit-ivacondition-form .form-edit {
  text-align: left; }
  .edit-ivacondition-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-ivacondition-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-ivacondition-form .form-edit .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .edit-ivacondition-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-ivacondition-form .form-edit label {
    text-align: left; }
  .edit-ivacondition-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-ivacondition-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-ivacondition-form .form-add {
  text-align: left; }
  .add-ivacondition-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-ivacondition-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-ivacondition-form .form-add .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .add-ivacondition-form .form-add label {
    text-align: left; }
  .add-ivacondition-form .form-add .select-form {
    text-align: left; }
  .add-ivacondition-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-ivacondition-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-ivaconditions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-ivaconditions__header-switch {
    display: flex;
    align-items: center; }
    .list-ivaconditions__header-switch > button {
      margin-right: 20px; }

.list-ivaconditions__search {
  display: block;
  margin-bottom: 30px; }

.list-ivaconditions .button-ivacondition {
  margin: 6px; }

.edit-seller-form .form-edit {
  text-align: left; }
  .edit-seller-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-seller-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-seller-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-seller-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-seller-form .form-edit label {
    text-align: left; }
  .edit-seller-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-seller-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-seller-form .form-add {
  text-align: left; }
  .add-seller-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-seller-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-seller-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-seller-form .form-add label {
    text-align: left; }
  .add-seller-form .form-add .select-form {
    text-align: left; }
  .add-seller-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-seller-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-sellers__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-sellers__header-switch {
    display: flex;
    align-items: center; }
    .list-sellers__header-switch > button {
      margin-right: 20px; }

.list-sellers__search {
  display: block;
  margin-bottom: 30px; }

.list-sellers .button-seller {
  margin: 6px; }

.edit-statebill-form .form-edit {
  text-align: left; }
  .edit-statebill-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-statebill-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-statebill-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-statebill-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-statebill-form .form-edit label {
    text-align: left; }
  .edit-statebill-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-statebill-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-statebill-form .form-add {
  text-align: left; }
  .add-statebill-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-statebill-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-statebill-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-statebill-form .form-add label {
    text-align: left; }
  .add-statebill-form .form-add .select-form {
    text-align: left; }
  .add-statebill-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-statebill-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-statebills__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-statebills__header-switch {
    display: flex;
    align-items: center; }
    .list-statebills__header-switch > button {
      margin-right: 20px; }

.list-statebills__search {
  display: block;
  margin-bottom: 30px; }

.list-statebills .button-statebill {
  margin: 6px; }

.edit-concept-form .form-edit {
  text-align: left; }
  .edit-concept-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-concept-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-concept-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-concept-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-concept-form .form-edit label {
    text-align: left; }
  .edit-concept-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-concept-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-concept-form .form-add {
  text-align: left; }
  .add-concept-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-concept-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-concept-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-concept-form .form-add label {
    text-align: left; }
  .add-concept-form .form-add .select-form {
    text-align: left; }
  .add-concept-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-concept-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-concepts__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-concepts__header-switch {
    display: flex;
    align-items: center; }
    .list-concepts__header-switch > button {
      margin-right: 20px; }

.list-concepts__search {
  display: block;
  margin-bottom: 30px; }

.list-concepts .button-concept {
  margin: 6px; }

.edit-salepoint-form .form-edit {
  text-align: left; }
  .edit-salepoint-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-salepoint-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-salepoint-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-salepoint-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-salepoint-form .form-edit label {
    text-align: left; }
  .edit-salepoint-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-salepoint-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-salepoint-form .form-add {
  text-align: left; }
  .add-salepoint-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-salepoint-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-salepoint-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-salepoint-form .form-add label {
    text-align: left; }
  .add-salepoint-form .form-add .select-form {
    text-align: left; }
  .add-salepoint-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-salepoint-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-salepoints__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-salepoints__header-switch {
    display: flex;
    align-items: center; }
    .list-salepoints__header-switch > button {
      margin-right: 20px; }

.list-salepoints__search {
  display: block;
  margin-bottom: 30px; }

.list-salepoints .button-salepoint {
  margin: 6px; }

.edit-salecondition-form .form-edit {
  text-align: left; }
  .edit-salecondition-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-salecondition-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-salecondition-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-salecondition-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-salecondition-form .form-edit label {
    text-align: left; }
  .edit-salecondition-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-salecondition-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-salecondition-form .form-add {
  text-align: left; }
  .add-salecondition-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-salecondition-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-salecondition-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-salecondition-form .form-add label {
    text-align: left; }
  .add-salecondition-form .form-add .select-form {
    text-align: left; }
  .add-salecondition-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-salecondition-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-saleconditions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-saleconditions__header-switch {
    display: flex;
    align-items: center; }
    .list-saleconditions__header-switch > button {
      margin-right: 20px; }

.list-saleconditions__search {
  display: block;
  margin-bottom: 30px; }

.list-saleconditions .button-salecondition {
  margin: 6px; }

.edit-suppliertype-form .form-edit {
  text-align: left; }
  .edit-suppliertype-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-suppliertype-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-suppliertype-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-suppliertype-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-suppliertype-form .form-edit label {
    text-align: left; }
  .edit-suppliertype-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-suppliertype-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-suppliertype-form .form-add {
  text-align: left; }
  .add-suppliertype-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-suppliertype-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-suppliertype-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-suppliertype-form .form-add label {
    text-align: left; }
  .add-suppliertype-form .form-add .select-form {
    text-align: left; }
  .add-suppliertype-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-suppliertype-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-suppliertypes__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-suppliertypes__header-switch {
    display: flex;
    align-items: center; }
    .list-suppliertypes__header-switch > button {
      margin-right: 20px; }

.list-suppliertypes__search {
  display: block;
  margin-bottom: 30px; }

.list-suppliertypes .button-suppliertype {
  margin: 6px; }

.edit-billtype-form .form-edit {
  text-align: left; }
  .edit-billtype-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-billtype-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-billtype-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-billtype-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-billtype-form .form-edit label {
    text-align: left; }
  .edit-billtype-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-billtype-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-billtype-form .form-add {
  text-align: left; }
  .add-billtype-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-billtype-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-billtype-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-billtype-form .form-add label {
    text-align: left; }
  .add-billtype-form .form-add .select-form {
    text-align: left; }
  .add-billtype-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-billtype-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-billtypes__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-billtypes__header-switch {
    display: flex;
    align-items: center; }
    .list-billtypes__header-switch > button {
      margin-right: 20px; }

.list-billtypes__search {
  display: block;
  margin-bottom: 30px; }

.list-billtypes .button-billtype {
  margin: 6px; }

.edit-billsystem-form .form-edit {
  text-align: left; }
  .edit-billsystem-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-billsystem-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-billsystem-form .form-edit .btn-submit {
    width: 100%; }
  .edit-billsystem-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-billsystem-form .form-edit label {
    text-align: left; }
  .edit-billsystem-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-billsystem-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-billsystem-form .form-add {
  text-align: left; }
  .add-billsystem-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-billsystem-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-billsystem-form .form-add .btn-submit {
    width: 100%; }
  .add-billsystem-form .form-add label {
    text-align: left; }
  .add-billsystem-form .form-add .select-form {
    text-align: left; }
  .add-billsystem-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-billsystem-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-billsystems__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-billsystems__header-switch {
    display: flex;
    align-items: center; }
    .list-billsystems__header-switch > button {
      margin-right: 20px; }

.list-billsystems__search {
  display: block;
  margin-bottom: 30px; }

.list-billsystems .button-billsystem {
  margin: 6px; }

.edit-paymenttype-form .form-edit {
  text-align: left; }
  .edit-paymenttype-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-paymenttype-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-paymenttype-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-paymenttype-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-paymenttype-form .form-edit label {
    text-align: left; }
  .edit-paymenttype-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-paymenttype-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-paymenttype-form .form-add {
  text-align: left; }
  .add-paymenttype-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-paymenttype-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-paymenttype-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-paymenttype-form .form-add label {
    text-align: left; }
  .add-paymenttype-form .form-add .select-form {
    text-align: left; }
  .add-paymenttype-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-paymenttype-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-paymenttypes__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-paymenttypes__header-switch {
    display: flex;
    align-items: center; }
    .list-paymenttypes__header-switch > button {
      margin-right: 20px; }

.list-paymenttypes__search {
  display: block;
  margin-bottom: 30px; }

.list-paymenttypes .button-paymenttype {
  margin: 6px; }

.edit-paymentmethod-form .form-edit {
  text-align: left; }
  .edit-paymentmethod-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-paymentmethod-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-paymentmethod-form .form-edit .btn-submit {
    margin-top: 5px;
    width: 100%; }
  .edit-paymentmethod-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-paymentmethod-form .form-edit label {
    text-align: left; }
  .edit-paymentmethod-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-paymentmethod-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-paymentmethod-form .form-add {
  text-align: left; }
  .add-paymentmethod-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-paymentmethod-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-paymentmethod-form .form-add .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .add-paymentmethod-form .form-add label {
    text-align: left; }
  .add-paymentmethod-form .form-add .select-form {
    text-align: left; }
  .add-paymentmethod-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-paymentmethod-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-paymentmethods__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-paymentmethods__header-switch {
    display: flex;
    align-items: center; }
    .list-paymentmethods__header-switch > button {
      margin-right: 20px; }

.list-paymentmethods__search {
  display: block;
  margin-bottom: 30px; }

.list-paymentmethods .button-paymentmethod {
  margin: 6px; }

.add-paymentorder-form .form-add {
  text-align: left; }
  .add-paymentorder-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 2px; }
    .add-paymentorder-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-paymentorder-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-paymentorder-form .form-add label {
    text-align: left; }
  .add-paymentorder-form .form-add .select-form {
    text-align: left; }
  .add-paymentorder-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-paymentorder-form .form-add .control-required {
    color: red;
    text-align: left; }
  .add-paymentorder-form .form-add .ant-input-disabled {
    color: black;
    background-color: white; }

.edit-paymentorder-form .form-edit {
  text-align: left; }
  .edit-paymentorder-form .form-edit .ant-form-item {
    margin-top: 2px;
    margin-bottom: 2px; }
    .edit-paymentorder-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-paymentorder-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-paymentorder-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-paymentorder-form .form-edit label {
    text-align: left; }
  .edit-paymentorder-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-paymentorder-form .form-edit .control-required {
    color: red;
    text-align: left; }
  .edit-paymentorder-form .form-edit .ant-input-disabled {
    color: black;
    background-color: white; }

.list-paymentorders__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-paymentorders__header-switch {
    display: flex;
    align-items: center; }
    .list-paymentorders__header-switch > button {
      margin-right: 20px; }

.list-paymentorders__search {
  display: block;
  margin-bottom: 30px; }

.list-paymentorders .button-paymentorder {
  margin: 6px; }

.edit-person-form .upload-avatar {
  display: table;
  margin: 0 auto;
  border: 2px solid #9a9a9a;
  border-style: dashed;
  border-radius: 100px;
  padding: 10px;
  margin-bottom: 20px; }

.edit-person-form .form-edit {
  text-align: left; }
  .edit-person-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .edit-person-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-person-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-person-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-person-form .form-edit label {
    text-align: left; }
  .edit-person-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-person-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-person-form .form-add {
  text-align: left; }
  .add-person-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-person-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-person-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-person-form .form-add label {
    text-align: left; }
  .add-person-form .form-add .select-form {
    text-align: left; }
  .add-person-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-person-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-persons__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-persons__header-switch {
    display: flex;
    align-items: center; }
    .list-persons__header-switch > button {
      margin-right: 20px; }

.list-persons__search {
  display: block;
  margin-bottom: 20px; }

.list-persons .persons-active {
  background-color: #fff;
  padding: 10px 20px; }
  .list-persons .persons-active .ant-list-item-meta {
    display: flex; }


.edit-deliveryTerm-form .form-edit {
  text-align: left; }
  .edit-deliveryTerm-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-deliveryTerm-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-deliveryTerm-form .form-edit .btn-submit {
    width: 100%; }
  .edit-deliveryTerm-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-deliveryTerm-form .form-edit label {
    text-align: left; }
  .edit-deliveryTerm-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-deliveryTerm-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-deliveryTerm-form .form-add {
  text-align: left; }
  .add-deliveryTerm-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-deliveryTerm-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-deliveryTerm-form .form-add .btn-submit {
    width: 100%; }
  .add-deliveryTerm-form .form-add label {
    text-align: left; }
  .add-deliveryTerm-form .form-add .select-form {
    text-align: left; }
  .add-deliveryTerm-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-deliveryTerm-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-deliveryTerms__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-deliveryTerms__header-switch {
    display: flex;
    align-items: center; }
    .list-deliveryTerms__header-switch > button {
      margin-right: 20px; }

.list-deliveryTerms__search {
  display: block;
  margin-bottom: 30px; }

.list-deliveryTerms .button-deliveryTerm {
  margin: 6px; }

.edit-stateBuyOrder-form .form-edit {
  text-align: left; }
  .edit-stateBuyOrder-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-stateBuyOrder-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-stateBuyOrder-form .form-edit .btn-submit {
    width: 100%; }
  .edit-stateBuyOrder-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-stateBuyOrder-form .form-edit label {
    text-align: left; }
  .edit-stateBuyOrder-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-stateBuyOrder-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-stateBuyOrder-form .form-add {
  text-align: left; }
  .add-stateBuyOrder-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-stateBuyOrder-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-stateBuyOrder-form .form-add .btn-submit {
    width: 100%; }
  .add-stateBuyOrder-form .form-add label {
    text-align: left; }
  .add-stateBuyOrder-form .form-add .select-form {
    text-align: left; }
  .add-stateBuyOrder-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-stateBuyOrder-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-stateBuyOrders__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-stateBuyOrders__header-switch {
    display: flex;
    align-items: center; }
    .list-stateBuyOrders__header-switch > button {
      margin-right: 20px; }

.list-stateBuyOrders__search {
  display: block;
  margin-bottom: 30px; }

.list-stateBuyOrders .button-stateBuyOrder {
  margin: 6px; }

.edit-buyorder-form .form-edit {
  text-align: left; }
  .edit-buyorder-form .form-edit .ant-form-item {
    margin-top: 2px;
    margin-bottom: 5px; }
    .edit-buyorder-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .edit-buyorder-form .form-edit .ant-form-item .button-add {
      margin-top: 20px; }
  .edit-buyorder-form .form-edit .btn-submit {
    width: 100%; }
  .edit-buyorder-form .form-edit label {
    text-align: left; }
  .edit-buyorder-form .form-edit .select-form {
    text-align: left; }
  .edit-buyorder-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-buyorder-form .form-edit .control-required {
    color: red;
    text-align: left; }
  .edit-buyorder-form .form-edit .ant-table-body {
    min-height: 150px; }
  .edit-buyorder-form .form-edit .ant-input-disabled {
    color: black;
    background-color: white; }

.edit-buyorder-form .bg-success {
  background: #70C040;
  border-color: #70C040;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.edit-buyorder-form .bg-warning {
  background-color: #EFAF41;
  border-color: #EFAF41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.edit-state-form .form-edit {
  text-align: left; }
  .edit-state-form .form-edit .ant-form-state {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-state-form .form-edit .ant-form-state i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-state-form .form-edit .btn-submit {
    width: 100%; }
  .edit-state-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-state-form .form-edit label {
    text-align: left; }
  .edit-state-form .form-edit .control-label {
    color: blue;
    text-align: left; }

.h4-unpaid {
  color: red;
  text-align: left; }

.h4-paid {
  color: green;
  text-align: left; }

.bg-success {
  color: #fff;
  background: #70C040;
  border-color: #70C040;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.bg-warning {
  background-color: #EFAF41; }

.add-buyorder-form .form-add {
  text-align: left; }
  .add-buyorder-form .form-add .ant-form-item {
    margin-top: 2px;
    margin-bottom: 5px; }
    .add-buyorder-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .add-buyorder-form .form-add .ant-form-item .button-add {
      margin-top: 20px; }
  .add-buyorder-form .form-add .btn-submit {
    width: 100%; }
  .add-buyorder-form .form-add label {
    text-align: left; }
  .add-buyorder-form .form-add .select-form {
    text-align: left; }
  .add-buyorder-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-buyorder-form .form-add .control-required {
    color: red;
    text-align: left; }
  .add-buyorder-form .form-add .ant-table-body {
    min-height: 150px; }
  .add-buyorder-form .form-add .ant-input-disabled {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: "nowrap";
    color: black;
    background-color: white; }

.add-buyorder-form .bg-success {
  background: #70c040;
  border-color: #70c040;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.add-buyorder-form .bg-warning {
  background-color: #efaf41;
  border-color: #efaf41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-buyorders__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-buyorders__header-switch {
    display: flex;
    align-items: center; }
    .list-buyorders__header-switch > button {
      margin-right: 20px; }

.list-buyorders__search {
  display: block;
  margin-bottom: 50px; }

.list-buyorders .hover {
  position: relative; }
  .list-buyorders .hover:hover .list-buyorders .hover__no-hover {
    opacity: 0; }
  .list-buyorders .hover:hover .list-buyorders .hover__hover {
    opacity: 1; }
  .list-buyorders .hover__hover {
    position: absolute;
    top: 0;
    opacity: 0; }
  .list-buyorders .hover__no-hover {
    opacity: 1; }

.list-buyorders .button-buyorder {
  margin: 6px; }

.list-buyorders .bg-success {
  color: #fff;
  background: #70C040;
  border-color: #70C040;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-buyorders .bg-warning {
  background-color: #EFAF41;
  border-color: #EFAF41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-buyorders .buyorders {
  background-color: #fff;
  padding: 10px 20px; }
  .list-buyorders .buyorders .ant-list-item-meta {
    display: flex; }


.edit-item-form .form-edit {
  text-align: left; }
  .edit-item-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-item-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-item-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-item-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-item-form .form-edit label {
    text-align: left; }
  .edit-item-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-item-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-item-form .form-add {
  text-align: left; }
  .add-item-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-item-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-item-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-item-form .form-add label {
    text-align: left; }
  .add-item-form .form-add .select-form {
    text-align: left; }
  .add-item-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-item-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-items__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-items__header-switch {
    display: flex;
    align-items: center; }
    .list-items__header-switch > button {
      margin-right: 20px; }

.list-items__search {
  display: block;
  margin-bottom: 30px; }

.list-items .button-item {
  margin: 6px; }


.edit-category-form .form-edit {
  text-align: left; }
  .edit-category-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-category-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-category-form .form-edit .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .edit-category-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-category-form .form-edit label {
    text-align: left; }
  .edit-category-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-category-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-category-form .form-add {
  text-align: left; }
  .add-category-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-category-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-category-form .form-add .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .add-category-form .form-add label {
    text-align: left; }
  .add-category-form .form-add .select-form {
    text-align: left; }
  .add-category-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-category-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-categories__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-categories__header-switch {
    display: flex;
    align-items: center; }
    .list-categories__header-switch > button {
      margin-right: 20px; }

.list-categories__search {
  display: block;
  margin-bottom: 30px; }

.list-categories .button-category {
  margin: 6px; }

.edit-cointype-form .form-edit {
  text-align: left; }
  .edit-cointype-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-cointype-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-cointype-form .form-edit .btn-submit {
    width: 100%; }
  .edit-cointype-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-cointype-form .form-edit label {
    text-align: left; }
  .edit-cointype-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-cointype-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-cointype-form .form-add {
  text-align: left; }
  .add-cointype-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-cointype-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-cointype-form .form-add .btn-submit {
    width: 100%; }
  .add-cointype-form .form-add label {
    text-align: left; }
  .add-cointype-form .form-add .select-form {
    text-align: left; }
  .add-cointype-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-cointype-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-coinTypes__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-coinTypes__header-switch {
    display: flex;
    align-items: center; }
    .list-coinTypes__header-switch > button {
      margin-right: 20px; }

.list-coinTypes__search {
  display: block;
  margin-bottom: 30px; }

.list-coinTypes .button-coinType {
  margin: 6px; }

.edit-merchandiseorder-form .form-edit {
  text-align: left; }
  .edit-merchandiseorder-form .form-edit .ant-form-item {
    margin-top: 2px;
    margin-bottom: 5px; }
    .edit-merchandiseorder-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .edit-merchandiseorder-form .form-edit .ant-form-item .button-add {
      margin-top: 20px; }
  .edit-merchandiseorder-form .form-edit .btn-submit {
    width: 100%; }
  .edit-merchandiseorder-form .form-edit label {
    text-align: left; }
  .edit-merchandiseorder-form .form-edit .select-form {
    text-align: left; }
  .edit-merchandiseorder-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-merchandiseorder-form .form-edit .control-required {
    color: red;
    text-align: left; }
  .edit-merchandiseorder-form .form-edit .ant-table-body {
    min-height: 150px; }
  .edit-merchandiseorder-form .form-edit .ant-input-disabled {
    color: black;
    background-color: white; }

.edit-merchandiseorder-form .bg-success {
  background: #70C040;
  border-color: #70C040;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.edit-merchandiseorder-form .bg-warning {
  background-color: #EFAF41;
  border-color: #EFAF41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.edit-state-form .form-edit {
  text-align: left; }
  .edit-state-form .form-edit .ant-form-state {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-state-form .form-edit .ant-form-state i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-state-form .form-edit .btn-submit {
    width: 100%; }
  .edit-state-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-state-form .form-edit label {
    text-align: left; }
  .edit-state-form .form-edit .control-label {
    color: blue;
    text-align: left; }

.h4-unpaid {
  color: red;
  text-align: left; }

.h4-paid {
  color: green;
  text-align: left; }

.bg-success {
  color: #fff;
  background: #70C040;
  border-color: #70C040;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.bg-warning {
  background-color: #EFAF41; }

.add-merchandiseorder-form .form-add {
  text-align: left; }
  .add-merchandiseorder-form .form-add .ant-form-item {
    margin-top: 2px;
    margin-bottom: 5px; }
    .add-merchandiseorder-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .add-merchandiseorder-form .form-add .ant-form-item .button-add {
      margin-top: 20px; }
  .add-merchandiseorder-form .form-add .btn-submit {
    width: 100%; }
  .add-merchandiseorder-form .form-add label {
    text-align: left; }
  .add-merchandiseorder-form .form-add .select-form {
    text-align: left; }
  .add-merchandiseorder-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-merchandiseorder-form .form-add .control-required {
    color: red;
    text-align: left; }
  .add-merchandiseorder-form .form-add .ant-table-body {
    min-height: 150px; }
  .add-merchandiseorder-form .form-add .ant-input-disabled {
    color: black;
    background-color: white; }

.add-merchandiseorder-form .bg-success {
  background: #70c040;
  border-color: #70c040;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.add-merchandiseorder-form .bg-warning {
  background-color: #efaf41;
  border-color: #efaf41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-merchandiseorders__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-merchandiseorders__header-switch {
    display: flex;
    align-items: center; }
    .list-merchandiseorders__header-switch > button {
      margin-right: 20px; }

.list-merchandiseorders__search {
  display: block;
  margin-bottom: 50px; }

.list-merchandiseorders .hover {
  position: relative; }
  .list-merchandiseorders .hover:hover .list-merchandiseorders .hover__no-hover {
    opacity: 0; }
  .list-merchandiseorders .hover:hover .list-merchandiseorders .hover__hover {
    opacity: 1; }
  .list-merchandiseorders .hover__hover {
    position: absolute;
    top: 0;
    opacity: 0; }
  .list-merchandiseorders .hover__no-hover {
    opacity: 1; }

.list-merchandiseorders .button-buyorder {
  margin: 6px; }

.list-merchandiseorders .bg-success {
  color: #fff;
  background: #70C040;
  border-color: #70C040;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-merchandiseorders .bg-warning {
  background-color: #EFAF41;
  border-color: #EFAF41;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.list-merchandiseorders .buyorders {
  background-color: #fff;
  padding: 10px 20px; }
  .list-merchandiseorders .buyorders .ant-list-item-meta {
    display: flex; }


.edit-unit-form .form-edit {
  text-align: left; }
  .edit-unit-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-unit-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-unit-form .form-edit .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .edit-unit-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-unit-form .form-edit label {
    text-align: left; }
  .edit-unit-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-unit-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-unit-form .form-add {
  text-align: left; }
  .add-unit-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-unit-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-unit-form .form-add .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .add-unit-form .form-add label {
    text-align: left; }
  .add-unit-form .form-add .select-form {
    text-align: left; }
  .add-unit-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-unit-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-units__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-units__header-switch {
    display: flex;
    align-items: center; }
    .list-units__header-switch > button {
      margin-right: 20px; }

.list-units__search {
  display: block;
  margin-bottom: 30px; }

.list-units .button-unit {
  margin: 6px; }

.edit-brand-form .form-edit {
  text-align: left; }
  .edit-brand-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-brand-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-brand-form .form-edit .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .edit-brand-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-brand-form .form-edit label {
    text-align: left; }
  .edit-brand-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-brand-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-brand-form .form-add {
  text-align: left; }
  .add-brand-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-brand-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-brand-form .form-add .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .add-brand-form .form-add label {
    text-align: left; }
  .add-brand-form .form-add .select-form {
    text-align: left; }
  .add-brand-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-brand-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-brands__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-brands__header-switch {
    display: flex;
    align-items: center; }
    .list-brands__header-switch > button {
      margin-right: 20px; }

.list-brands__search {
  display: block;
  margin-bottom: 30px; }

.list-brands .button-brand {
  margin: 6px; }

.edit-type-form .form-edit {
  text-align: left; }
  .edit-type-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-type-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-type-form .form-edit .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .edit-type-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-type-form .form-edit label {
    text-align: left; }
  .edit-type-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-type-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-type-form .form-add {
  text-align: left; }
  .add-type-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-type-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-type-form .form-add .btn-submit {
    margin-top: 8px;
    width: 100%; }
  .add-type-form .form-add label {
    text-align: left; }
  .add-type-form .form-add .select-form {
    text-align: left; }
  .add-type-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-type-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-types__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-types__header-switch {
    display: flex;
    align-items: center; }
    .list-types__header-switch > button {
      margin-right: 20px; }

.list-types__search {
  display: block;
  margin-bottom: 30px; }

.list-types .button-type {
  margin: 6px; }

.edit-stateinventory-form .form-edit {
  text-align: left; }
  .edit-stateinventory-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-stateinventory-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-stateinventory-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-stateinventory-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-stateinventory-form .form-edit label {
    text-align: left; }
  .edit-stateinventory-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-stateinventory-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-stateinventory-form .form-add {
  text-align: left; }
  .add-stateinventory-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-stateinventory-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-stateinventory-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-stateinventory-form .form-add label {
    text-align: left; }
  .add-stateinventory-form .form-add .select-form {
    text-align: left; }
  .add-stateinventory-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-stateinventory-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-stateinventories__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-stateinventories__header-switch {
    display: flex;
    align-items: center; }
    .list-stateinventories__header-switch > button {
      margin-right: 20px; }

.list-stateinventories__search {
  display: block;
  margin-bottom: 30px; }

.list-stateinventories .button-stateinventory {
  margin: 6px; }

.App h1 {
  color: #0098d3; }

.App h2 {
  color: green; }

.App span {
  font-weight: 100; }

.success {
  border: 1px solid black;
  background-color: #50e2011f; }

.error {
  border: 1px solid #f00;
  background-color: #ff00001f; }

#scroll-y {
  overflow-y: auto;
  overflow-x: hidden; }

#scroll-hidden {
  overflow-y: hidden;
  overflow-x: hidden; }

