.add-buybill-form {
  .form-add {
    text-align: left;

    .ant-form-item {
      margin-top: 2px;
      margin-bottom: 5px;

      i {
        color: rgba(0, 0, 0, 0.25);
      }

      .button-add {
        margin-top: 20px;
      }
    }

    .btn-submit {
      width: 100%;
    }
    label {
      text-align: left;
    }
    .select-form {
      text-align: left;
    }
    .control-label {
      color: blue;
      text-align: left;
    }
    .control-required {
      color: red;
      text-align: left;
    }
    .ant-table-body {
      min-height: 150px;
    }
    .ant-input-disabled {
      color:black;
      background-color: white}
  }
  .bg-success {
    background: #70c040;
    border-color: #70c040;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .bg-warning {
    background-color: #efaf41;
    border-color: #efaf41;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
}
