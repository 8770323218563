.list-branch-categories {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-switch {
            display: flex;
            align-items: center;

            > button {
                margin-right: 20px;
            }
        }
    }

    &__search{
        display:block;
        margin-bottom: 30px;
    }

    .button-edit{
        margin: 6px;
        //background-color: rgb(0, 102, 255);
        background-image: linear-gradient(to right, #007bff, #4ab2e2);
        color: white;
    }
    .button-available{
        margin: 6px;
        background-image: linear-gradient(to right, #6c757d, #adb5bd);
        color: white;
    }
    .button-add{
        margin: 6px;
        background-image: linear-gradient(to right, #ffb200, #ffda4e);
        color: white;
    }
    .button-delete{
        margin: 6px;
        background-image: linear-gradient(to right, #dc3545, #e0666e);
        color: white;
    }
    .ant-table-footer{
        font-weight: bold;
    }

    .ant-collapse {
        border-color: "#ff7640";
        //background-image: linear-gradient(to right, #a9b5c0, #d5e0eb);
        // #6253e1, #04befe
        background-image: linear-gradient(to left, #a9b5c0, #d5e0eb);
        //background-image: linear-gradient(to right, #5b9dfa, #094b79);
        border-radius: 8px;
     }

    .ant-panel{

        .ant-collapse-header{
            font-weight: bold;
            font-size: 16px;
            font-family: sans-serif;
        }
        

    }
}