$defaultFontSize: 16px;

$primary-color: #0098d3;
$primary-color-dark: #0280b3;
$primary-color-hover: #4ec0ec;

$menu-color: #252527;

$font-light: #fff;
$font_grey-light: #e8e8e8;
$font-grey: #808090;
$font-grey-dark: #808088;
$font-dark-light: #252527;
$font-dark: #000;

$border-light: #fff;
$border-light: #e8e8e8;
$border-grey: #808090;
$border-grey-dark: #808088;
$border-dark-light: #252527;
$border-dark: #000;

$background-light: #fff;
$background-light: #e8e8e8;
$background-grey: #808090;
$background-grey-dark: #808088;
$background-dark-light: #252527;
$background-dark: #000;
$background-error: #ff00001f;
//$background-success: #84b84c1f;
$background-success: #50e2011f;

$success: black;
$error: #f00;
$warning: orange;

// Responsive down size
$media-breakpoint-down-xs: 413.98px;
$media-breakpoint-down-sm: 573.98px;
$media-breakpoint-down-md: 767.98px;
$media-breakpoint-down-lg: 991.98px;
$media-breakpoint-down-xl: 1199.98px;

// Responsive up size
$media-breakpoint-up-xs: 320px;
$media-breakpoint-up-sm: 576px;
$media-breakpoint-up-md: 768px;
$media-breakpoint-up-lg: 992px;
$media-breakpoint-up-xl: 1200px;