.ant-dropdown-button{
    width: 110px;

  .ant-dropdown-button > button {
    width: 110px;
    //overflow: hidden;
  }
  
  .ant-dropdown-button > button > span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

  }


}