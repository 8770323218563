@import '../../../scss/index.scss';

.admin-sider {
    background-image: url('../../../assets/img/png/azul1.png');
    left: 0;
    //top: 65px;
    height: 100vh;
    //height: min-content;
    padding-top: 4%;
    border-bottom-right-radius: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 1;
    //color: white;
    //background-color: white;

    button {
        //background-image: url('../../../assets/img/png/light.png');
        //color: white;
        padding-left: 0em;
        //border: 0;
        color: white;

        &:hover {
            color: $primary-color;
            //color: black;
        }
        &:focus,
        &:active {
            //color: $primary-color-hover;
            color: black;
        }
    }


    &__sider {
        position: fixed;
        left: 0;
        width: 50%;
        display: flex;
        padding: 0;
        height: 220px;
        background-color: white;
        border-bottom-right-radius: 50px;
        color: white;
    }

    /*
    .ant-menu{
        background-image: url('../../../assets/img/png/azul1.png');
        background-color: white;
        color: white;
    }
    */
}

.layout-init{
    background: white;
    color: white;
    padding: 0;
}

#scroll-y {
    overflow-y: scroll;
    overflow-x: hidden;
}

#scroll-x {
    overflow-x: scroll;
}

#scroll {
    overflow: scroll;
}

#scroll-b {
    scroll-behavior: initial;
}

#scroll-hidden {
    overflow-y: hidden;
    overflow-x: hidden;
}

// Side Navigation
.side-nav {
    background-image: url('../../../assets/img/png/azul1.png');
    padding-top: 1.5rem;
    //color: white;
}

.side-nav ul {
    margin: 0;
    padding: 0;
    //color: white;
}

.side-nav ul li a {
    padding: 0.75rem 2rem;
    font-size: 1.2rem;
    //color: white;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
}

/*
button {
    //background-image: url('../../../assets/img/png/light.png');
    color: white;
    padding-left: 0em;
    //border: 0;

    &:hover {
        //color: $primary-color;
        color: black;
    }
    &:focus,
    &:active {
        //color: $primary-color-hover;
        color: black;
    }
}
*/

.nac-text {
    color: $primary-color-hover;

    &:hover {
        color: black;
    }
    &:focus,
    &:active {
        color: black;
    }

    /*
    &:hover {
        //color: $primary-color;
        color: black;
    }
    &:focus,
    &:active {
        //color: $primary-color-hover;
        color: black;
    }
    */
}


/*
.nac-text {
    color: black;
}

.menu-top-web__item {
    color: black;
}
*/