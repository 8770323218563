@import '../scss/index.scss';

.layout-init{
    background: white;
    padding: 0;
    color: white;
}
.layout-admin {
    transition: margin-left 0.2s;
    margin: 0 auto;
    color: white;
    

    &__header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        padding: 0;
        height: 65px;
        background-image: url('../assets/img/png/azul1.png');
        border-bottom-right-radius: 50px;
        color: white;
    }

    &__sider {
        position: fixed;
        left: 0;
        width: 50%;
        display: flex;
        padding: 0;
        height: 220px;
        background-image: url('../assets/img/png/azul1.png');
        border-bottom-right-radius: 50px;
        color: white;
        margin-top: 300px;
    }

    //CONTENT DE LA APP
    &__content {
        min-height: 88vh;
        //height: auto;
        padding: 90px 30px 0px 0px;
        background: white;
        overflow-y: initial;
        color: white;
    }

    &__footer {
        background-color: white;
        background-position: center center;
        //background-size: cover;
        bottom: 0;
        //left: 0;
        padding: 8px;
        width: 100%;
        text-align: center;
        size: 25px;
        color: gray;
        font-weight: bold;
        position: static;
        /*margin: 20px;*/
    }
}

#scroll-y {
    overflow-y: auto;
    overflow-x: hidden;
}

#scroll-hidden {
    overflow-y: hidden;
    overflow-x: hidden;
}

// Side Navigation
.side-nav {
    background-color: aqua;
    padding-top: 1.5rem;
    color: white;

}

.side-nav ul {
    margin: 0;
    padding: 0;
    color: white;
}

.side-nav ul li a {
    padding: 0.75rem 2rem;
    font-size: 1.2rem;
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
}

