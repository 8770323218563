.edit-billsell-form {

    .form-edit {
        text-align: left;

        .ant-form-item {
            margin-top: 2px;
            margin-bottom: 2px;

            i {
                color: rgba(0,0,0,0.25);
            }

            .button-add {
                margin-top: 20px;
            }
        }

        .btn-submit {
            width: 100%;
        }
        .select-form {
            width: 100%;
            text-align: left;
        }
        label {
            text-align: left;
        }
        .control-label {
            color: blue;
            text-align: left;
        }
        .control-required{
            color:red;
            text-align: left;
        }
        .ant-table-body {
            min-height: 150px;
          }

        .ant-input-disabled {
            color:black;
            background-color: white}
    }
}